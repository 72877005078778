<template>
  <base-source-details-page>
    <page-not-found slot="page-not-found"></page-not-found>
  </base-source-details-page>
</template>

<script>
import BaseSourceDetailsPage from '@common/pages/sourceDetails/BaseSourceDetailsPage';

import PageNotFound from '@/components/page.not.found';

export default {
  components: {
    BaseSourceDetailsPage,
    PageNotFound,
  },
  name: 'SourceDetailsPage',
};
</script>

<style lang="scss" scoped></style>
