<template>
  <div v-if="isNotFound">
    <slot name="page-not-found"></slot>
  </div>
  <div class="collection-details-page" v-else>
    <div class="readable_content">
      <mcr-loading-indicator v-if="loading" :loading="true"></mcr-loading-indicator>
      <div class="details-content" v-else>
        <h5>{{ title }}</h5>
        <summary-text
          v-if="collectionDetailsState.summary"
          :summary="collectionDetailsState.summary"
          :summary-preview="collectionDetailsState.summary_preview"
          class="sub-section"
        ></summary-text>

        <div class="section" v-if="collectionDetailsState.images_count">
          <b>Images available:</b><span> {{ collectionDetailsState.images_count }}</span>
        </div>
        <div v-if="collectionDetailsState.persons_count">
          <b>Total persons indexed:</b><span> {{ collectionDetailsState.persons_count }}</span>
        </div>

        <div class="section" v-if="collectionDetailsState.persons_count">
          <stand-out-block class="light">
            <div class="heading-6 title form-title">Search this collection</div>
            <div class="filter-group form-item">
              <div class="filter">
                <label class="input-label">First Name</label>
                <input
                  v-capitalize
                  :value="formData.first_name"
                  class="input"
                  @input="onInputChange('first_name', $event.target.value)"
                />
              </div>
              <div class="filter">
                <label class="input-label">Last Name</label>
                <input
                  v-capitalize
                  :value="formData.surname"
                  class="input"
                  @input="onInputChange('surname', $event.target.value)"
                />
              </div>
            </div>
            <div class="filter-group form-item">
              <place-input
                class="filter"
                label="Place"
                :value="formData.place || {}"
                :show-default-options="false"
                :only-ancestral-places="false"
                multiselect-classes="bordered"
                label-classes="input-label"
                @select="onInputChange('place', $event)"
              ></place-input>
              <div class="filter-group">
                <div class="filter small">
                  <label for="record_date" class="input-label">Year</label>
                  <div>
                    <input
                      id="record_date"
                      :value="formData.year"
                      placeholder="YYYY"
                      class="input record-year"
                      @input="onInputChange('year', $event.target.value)"
                    />
                  </div>
                </div>
                <mcr-button class="search-button" @click="onSearchClick">
                  <search-icon class="search-icon" :size="20"></search-icon>
                  <span class="bold">Search</span>
                </mcr-button>
              </div>
            </div>
            <div class="filter-group form-item search-all">
              <router-link :to="browseAllRoute">Browse all records in this collection</router-link>
            </div>
          </stand-out-block>
        </div>

        <div class="details-table section">
          <details-item label="Title (pinyin)" v-if="collectionDetailsState.title_pinyin">{{
            collectionDetailsState.title_pinyin
          }}</details-item>
          <details-item label="Time Period" v-if="timePeriod">
            {{ timePeriod }}
          </details-item>
          <details-item label="Places" v-if="collectionDetailsState.places.length">
            <router-link
              v-for="place in collectionDetailsState.places"
              :key="place.id"
              :to="getPlaceRoute(place.id, place.pinyin)"
              class="name"
              >{{ place.pinyin }}</router-link
            >
          </details-item>
          <details-item label="Ancestral Places" v-if="collectionDetailsState.ancestral_places.length">
            <router-link
              v-for="place in collectionDetailsState.ancestral_places"
              :key="place.id"
              :to="getPlaceRoute(place.id, place.pinyin)"
              class="name"
              >{{ place.pinyin }}</router-link
            >
          </details-item>
          <details-item label="Clans" v-if="collectionDetailsState.clans.length">
            <link-surname-page-family
              :clans="collectionDetailsState.clans"
              :show-family="false"
            ></link-surname-page-family>
          </details-item>
          <details-item label="Cemeteries" v-if="collectionDetailsState.cemeteries.length">
            <router-link
              v-for="cemetery in collectionDetailsState.cemeteries"
              class="name"
              :key="cemetery.object_id"
              :to="getCemeteryRoute(cemetery)"
              >{{ cemetery.name }}</router-link
            >
          </details-item>
          <details-item label="Associations" v-if="collectionDetailsState.associations.length">
            <span class="name" v-for="association in collectionDetailsState.associations">
              {{ association.name_en }}
            </span>
          </details-item>
        </div>

        <div class="section">
          <stand-out-block-titled>
            <template v-slot:heading>
              <div class="heading-6 title">Sources Within Collection</div>
            </template>
            <template v-slot:content>
              <div class="source-items">
                <div
                  class="source-item text-sm text-md-mobile"
                  v-for="source in collectionDetailsState.sources"
                  :key="source.id"
                >
                  <div class="source-name">
                    <router-link :to="getSourceRoute(source)" class="link">{{
                      source.title_en || source.title_ch
                    }}</router-link>
                  </div>
                  <div class="source-type">{{ source.type_display }}</div>
                </div>
              </div>
            </template>
          </stand-out-block-titled>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import PlaceInput from '@common/elements/inputs/PlaceInput';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import StandOutBlockTitled from '@common/elements/layouts/StandOutBlockTitled';
import SummaryText from '@common/elements/layouts/SummaryText';
import detailsItem from '@common/elements/layouts/detailsItem';
import linkSurnamePageFamily from '@common/elements/links/linkSurnamePageFamily';
import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {getCemeteryDetailRoute, getPlaceDetailRoute} from '@common/utils/utils.routes';
import SearchIcon from 'vue-material-design-icons/Magnify';
import {mapGetters} from 'vuex';

export default {
  metaInfo() {
    return {
      title: this.title,
    };
  },
  created() {
    this.$store
      .dispatch('fetchCollectionDetailsAction', this.$route.params.id)
      .catch(error => {
        this.isNotFound = true;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: true,
      isNotFound: false,
      formData: {
        first_name: '',
        surname: '',
        place: {},
        year: '',
      },
    };
  },
  computed: {
    ...mapGetters(['collectionDetailsState']),
    title() {
      const {title_en, title_ch} = this.collectionDetailsState;
      const title = `${title_en || ''} ${title_ch || ''}`.trim();
      return this.timePeriod ? `${title}, ${this.timePeriod}` : title;
    },
    timePeriod() {
      const {time_period_start, time_period_end} = this.collectionDetailsState;
      return time_period_start || time_period_end ? `${time_period_start || '...'}-${time_period_end || '...'}` : '';
    },
    browseAllRoute() {
      return {name: 'search-all-records', query: {tab: TAB_ID_INDEXED_RECORDS, collection_id: this.$route.params.id}};
    },
  },
  methods: {
    getPlaceRoute(id, name) {
      return getPlaceDetailRoute(id, name);
    },
    getCemeteryRoute(cemetery) {
      return getCemeteryDetailRoute(cemetery.object_id, cemetery.name);
    },
    getSourceRoute(source) {
      return {name: 'source-detail', params: {sourceId: source.id}};
    },
    onInputChange(field, value) {
      this.formData = {...this.formData, [field]: value};
    },
    onSearchClick() {
      const query = {
        tab: TAB_ID_INDEXED_RECORDS,
        collection_id: this.$route.params.id,
        first_name: this.formData.first_name,
        surname: this.formData.surname,
        auto_place_id: this.formData.place && this.formData.place.id ? this.formData.place.id : '',
        record_year: this.formData.year || '',
      };
      this.$router.push({name: 'search-all-records', query});
    },
  },
  components: {
    SummaryText,
    detailsItem,
    linkSurnamePageFamily,
    StandOutBlockTitled,
    PlaceInput,
    McrButton,
    SearchIcon,
    StandOutBlock,
  },
  name: 'BaseCollectionDetailsPage',
};
</script>

<style lang="scss" scoped>
.collection-details-page {
  h5 {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid $divider-line-color;
  }
  .section {
    display: block;
    margin-top: 32px;
  }

  .title {
    color: $neutral-600;
  }
  .form-title {
    margin-bottom: 16px;
  }

  .source-items {
    display: flex;
    flex-direction: column;

    .source-item {
      padding: 12px 16px;
      display: flex;
      column-gap: 10px;
      justify-content: space-between;

      .source-type {
        flex-shrink: 0;
        color: $neutral-500;
      }
    }
  }

  .filter-group {
    display: flex;
    column-gap: 16px;
    flex-grow: 1;
    > * {
      width: 100%;
    }
    .filter {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
    }
    .filter.small {
      width: fit-content;
      flex-grow: 0;
      flex-shrink: 0;

      input {
        width: 100px;
      }
    }
  }
  .search-button {
    align-self: center;
    height: 44px;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .source-items {
      .source-item {
        flex-direction: column;
      }
    }
    .filter-group {
      flex-direction: column;
      .filter {
        max-width: 100%;
        width: 100%;
        margin-bottom: 16px;
      }
    }
    .search-button {
      align-self: stretch;
      width: 100%;
    }
    .search-all {
      margin-top: 16px;
    }
  }
}
</style>
