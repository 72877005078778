export default {
  onBeforeMount(vm) {
    vm.$store.commit('setSourceDetailsState', null);
    vm.$store.commit('clearSourceImagesState');
    vm.$store.commit('clearSourceImagesOcrState');
    vm.$store.commit('setSourceImagesSearchResultsState', []);
    vm.$store.commit('setSourceImagesSearchMetaState', {});
  },
  onSourceDetailsFetch(source, vm) {
    if (vm.currentPageNumber && source.images_count < vm.currentPageNumber) {
      vm.handlePageChange(source.images_count);
    }
    vm.fetchSourceImageWithOcr();
    if (vm.$route.query.search) {
      vm.fetchSearchResults();
    }
  },
  onSourceDetailsFetchError(error, vm) {
    if (error.response && error.response.data && error.response.data.is_offline) {
      vm.isRecordOffline = true;
    } else {
      vm.isRecordNotExist = true;
    }
  },
  onSearch(value, page, vm) {
    const currentSearchPage = vm.sourceImagesSearchMetaState.offset / vm.sourceImagesSearchMetaState.limit + 1;
    if (value === vm.searchValue && page === currentSearchPage) {
      return;
    }
    if (value === vm.searchValue && page !== currentSearchPage) {
      return vm.fetchSearchResults((page - 1) * vm.sourceImagesSearchMetaState.limit);
    }
    vm.searchValue = value;
    vm.saveToQuery({search: value});

    vm.$store.commit('clearSourceImagesMatchesVerticesState');
    vm.$store
      .dispatch('fetchSourceImageAction', {
        sourceId: vm.sourceId,
        imageNumber: vm.currentPageNumber,
        imageId: vm.currentPageId,
        search: vm.searchValue,
      })
      .then(res => {
        if (!vm.$route.query.page) {
          vm.handlePageChange(res.priority);
        }
      });
    vm.fetchSearchResults();
  },
  fetchSourceImageWithOcr(skipCache, ocrType, vm) {
    if (skipCache || !vm.sourceImagesState[vm.currentPageNumber - 1]) {
      vm.$store
        .dispatch('fetchSourceImageAction', {
          sourceId: vm.sourceId,
          imageNumber: vm.currentPageNumber,
          imageId: vm.currentPageId,
          search: vm.searchValue,
        })
        .then(res => {
          if (!vm.$route.query.page) {
            vm.handlePageChange(res.priority);
          }
        });
      vm.$store.dispatch('fetchSourceImageOcrAction', {
        sourceId: vm.sourceId,
        imageNumber: vm.currentPageNumber,
        imageId: vm.currentPageId,
        ocrType,
      });
    }
  },
  handlePageChange(pageNumber, vm) {
    vm.currentPageNumber = pageNumber;
    vm.saveToQuery({page: pageNumber});
    vm.removeFromQuery('page_id');
  },
  clearSearch(vm) {
    vm.searchValue = '';
    vm.saveToQuery({search: ''});
    vm.$store.commit('clearSourceImagesMatchesVerticesState');
    vm.$store.commit('setSourceImagesSearchResultsState', []);
    vm.$store.commit('setSourceImagesSearchMetaState', {});
  },
  onRouteQueryChange(toQuery, fromQuery, vm) {
    const newPage = parseInt(toQuery.page);
    const fromPage = parseInt(fromQuery.page);
    if (newPage && newPage !== fromPage && newPage !== vm.currentPageNumber) {
      vm.goToPage(newPage);
    }
  },
  getPreviousPageLabel(vm) {
    return vm.previousRoute && vm.previousRoute.name
      ? vm.previousRouteToLabel[vm.previousRoute.name] || vm.defaultPreviousPageLabel
      : vm.defaultPreviousPageLabel;
  },
};
